



















import { Component, Vue } from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {
  CustomFormType, FundProjectModifyApplyDto,
  OrganizationUnitCreateOrUpdateDto,
} from "@/api/appService";
import api from "@/api/index";

@Component({ name: "OrganizationUnitEdit", components: { CustomFormEdit } })
export default class OrganizationUnitEdit extends Vue {
  formId = 0;
  dataId = 0;

  created() {
    if (this.$route.query.formId) {
      this.formId = Number(this.$route.query.formId);
    }
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }

  get hostType() {
    return CustomFormType.OrganizationUnit;
  }

  get getData(): Promise<OrganizationUnitCreateOrUpdateDto> {
    if (this.dataId > 0) return api.organizationUnit.get({id: this.dataId});
    else return new Promise<OrganizationUnitCreateOrUpdateDto>(resolve => this.defaultData);
  }

  handleSave() {
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        let data = (
          this.$refs.customForm as any
        ).getRequestData() as OrganizationUnitCreateOrUpdateDto;
        let fn;
        if (this.$route.query.hostId) {
          data.organizationType = Number(this.$route.query.hostId);
        }
        data.formId = Number(this.$route.query.formId);
        data.extensionData = JSON.stringify(data.extensionObject);
        if (!this.dataId) {
          fn = api.organizationUnit.create;
        } else {
          fn = api.organizationUnit.update;
        }

        fn({ body: data }).then((res) => {
          this.$message.success("更新成功");
          this.$router.back();
        });
      } else {
        this.$message.error("请检查表单");
      }
    });
  }

  cancel() {
    this.$router.back();
  }
}
